/* You can add global styles to this file, and also import other style files */

@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import './app/calendar-material.css';
@import './app/button-material.css';

@import '~tippy.js/dist/tippy.css';
@import '~tippy.js/themes/light.css';
@import '~tippy.js/animations/scale.css';
/*mat-progress-bar .mat-progress-bar-fill {*/
/*  background-color: #374f6b;*/
/*}*/
/*mat-progress-bar .mat-progress-bar-buffer {*/
/*  background: #E4E8EB;*/
/*}*/
/*mat-progress-bar {*/
/*  border-radius: 2px;*/
/*}*/

.tooltip-sm {
  width: 10rem;
}


.disable-scroll {
  height: 100vh;
  overflow: hidden;
}

.md-tooltip {
  @media screen and (min-width: 2042px) {
    display: none;
  }
}

.primary-color-progress .mat-progress-bar-fill::after {
  background-color: #374f6b !important;
}

.mat-progress-bar-buffer {
  background: #E4E8EB;
}

.danger-color-progress .mat-progress-bar-fill::after {
  background-color: #843134 !important;
}

.success-color-progress .mat-progress-bar-fill::after {
  background-color: #2FC19A !important;
}

svg>g[class^="raphael-group-"]>text {
  display: none !important;
}

g[class$='creditgroup'] {
  display: none !important;
}

[pointer-events="bounding-box"] {
  display: none !important;
}

.pointer {
  cursor: pointer;
}

.cal-time-events {
  display: none !important;
}


.loading{
  position: fixed;
  z-index: 1000000;
  background-color: #fff;
  top:0;
  right:0;
  left:0;
  bottom: 0;
}